.champs-balance-card {
  width: 100%;
  height: 251px;
  border-radius: 20px;
  background-image: url("../../asset/Images/champs-card.png");
  background-size: cover; /* Cover the entire background */
  background-position: center;
}
.champscoin {
  width: 100px;
  height: 90px;
}
@media screen and (max-width: 768px) {
  .champs-balance-card {
    width: 100%;
  }
  .champscoin {
    width: 120px;
    height: 90px;
  }
}

/* .backgroud-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 20px;
  overflow: hidden;
} */

.card-header {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
}
