.card-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
}
/* 
@media (min-width: 900px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
} */
.white-footer-gradient {
  position: absolute;
  width: 80%;
  height: 5rem;
  background: #11b1f4;
  filter: blur(100px);
  border-radius: 50%;
  bottom: 0;
  display: flex;
  align-self: center;
  margin: 0 auto;
  left: 10rem;

  /* top: -20; */
  /* right: 40%; */
}

@media screen and (max-width: 768px) {
  .card-container {
    /* display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 50px;
    gap: 10px; */
    grid-template-columns: repeat(2, 1fr);
  }
}
/* * {
  margin: 0;
  padding: 0;
}
body {
  background-color: #0f103c;
  color: #ffffff;
  width: 100vw !important;
  overflow: hidden;
  position: relative;
} */
/* #root,
body,
html {
  height: 100%;
} */

/* html {
  font: 14px/1.21 Rubik, "Helvetica Neue", Arial, sans-serif;
  position: relative;
} */

/* #root > section {
  min-height: calc(100% - 57px);
  padding-top: 57px;
  display: flex;
  background: #111;
} */

/* #root > section > aside {
  background: #191c24;
} */

/* #root > section > div {
  padding-top: 2px;
  padding-bottom: 16px;
  flex: 1 1;
} */

/* #root > section > div > * {
  margin: 18px;
} */

/* h2 {
  padding-bottom: 14px;
  font-size: 1.5rem;
}

h4 {
  padding-bottom: 20px;
  font-size: 1.1rem;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.box {
  padding: 20px;
  background: #191c24;
  border-radius: 14px;
}

.box.noBackground {
  background-color: inherit;
}

.flex {
  margin-top: 18px;
  margin-right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex > * {
  flex: 1 1;
}

.flex > :not(:last-child) {
  margin-right: 18px;
}

@media only screen and (max-width: 768px) {
  #root > section {
    padding-top: 50px;
  }

  #root > section > div {
    max-height: calc(100vh - 57px);
    overflow: scroll;
  }

  #root > section > div > * {
    margin: 10px;
  }

  .box {
    padding: 12px;
  }

  .flex {
    margin-bottom: -9px;
    flex-direction: column;
  }

  .flex > :first-child {
    margin-top: 0;
  }

  .flex > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.buy .list {
  margin: -2px 0;
  flex-grow: 0.6;
  border-right: 1px solid #2c2e33;
}

.buy .list a {
  margin-top: 8px;
  display: block;
}

.buy .list a:first-child {
  margin-top: 0;
}

.buy .list .listItem {
  border-radius: 6px;
}

.buy .list .active .listItem {
  background: #111;
}

.banner-h {
  text-align: right;
}

.hodlBox {
  width: 500px;
}

.hodlBox > div.content {
  height: 65px;
  padding-left: 100px;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  background: url(/static/media/hodl.e672311d.png) 10px 3px no-repeat;
  background-size: 60px 58px;
}

.buy .listItem {
  margin-right: 20px;
  padding: 22px 16px 22px 175px;
  position: relative;
}

.buy .listItem:hover {
  background: #212530;
}

.buy .listItem:not(:last-child) {
  border-bottom: 1px solid #2c2e33;
}

.buy .listItem .logo {
  top: 0;
  left: 0;
  width: 170px;
  height: 100%;
  position: absolute;
}

.buy .listItem p {
  max-height: 50px;
  color: #989cb3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buy .selected {
  margin-left: 20px;
  margin-right: 0 !important;
  padding: 22px 16px;
  line-height: 22px;
}

.buy .selected:not(:last-child) {
  border-bottom: 1px solid #2c2e33;
}

.buy .selected .logo {
  width: 100%;
  height: 175px;
  margin-top: -25px;
}

.buy .selected p {
  color: #989cb3;
}

.buy .header {
  margin-top: 0;
  margin-bottom: 7px;
}

.buy .header strong {
  font-weight: 500;
}

.buy .header small {
  margin-right: 1px;
  color: #6c7293;
  font-size: 14px;
  text-align: right;
}

.buy .pairs {
  margin-top: 60px;
  text-align: center;
}

.buy .pairs a {
  margin-top: 25px;
  padding: 6px 50px;
  color: #00d25b;
  font-size: 1.1rem;
  background: none;
  border: 1px solid #00d25b;
  border-radius: 4px;
}

.buy .pairs a:hover {
  color: #fff;
  cursor: pointer;
  background-color: #00d25b;
}

.dashboard {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .dashboard {
    margin-top: 100px;
  }
}

.dashboard .hodlBox {
  width: auto;
}

.dashboard .hodlBox h2 {
  display: none;
}

.dashboard .hodlBox > .content {
  height: auto;
  overflow: visible;
}

.dashboard .hodlBox dl {
  line-height: 24px;
  overflow: auto;
}

.dashboard .hodlBox dt {
  width: 150px;
  clear: both;
  float: left;
  font-weight: 600;
}

.dashboard .hodlBox dd {
  float: left;
}

.dashboard .hodlBox dd a {
  color: #6c7293;
  font-family: Roboto, Monaco, monospace;
  font-size: 0.95rem;
}

.dashboard .hodlBox dd a:hover {
  background: #000;
}

.dashboard .hodlBox dd div {
  margin-bottom: -3px;
}

.dashboard .hodlBox dd div strong {
  font-weight: 500;
}

.dashboard .hodlBox dd div:hover strong.toggle,
.dashboard .hodlBox dd div strong.exact {
  display: none;
}

.dashboard .hodlBox dd div:hover strong.exact {
  display: inline;
}

.dashboard .hodlBox dd label {
  margin-left: 6px;
  color: #6c7293;
} */

/* .dashboard .market h2 {
  margin-bottom: 4px;
  font-weight: 600;
}

.dashboard .market dl {
  padding: 10px 19px 17px;
  font-size: 1.05rem;
  background: #111;
  border-radius: 14px;
}

.dashboard .market dt {
  padding-top: 11px;
  font-weight: 600;
}

.dashboard .market dd {
  padding-top: 3px;
}

.banner-v {
  flex: 0 1 !important;
}

.banner-v img {
  width: 155px;
  opacity: 0.9;
}

.stat {
  display: flex;
}

.stat label {
  padding-bottom: 5px;
  display: block;
  font-size: 1.85rem;
}

.stat:hover label.metric.toggle,
.stat label.exact {
  display: none;
}

.stat:hover label.exact {
  display: block;
}

.stat sub {
  color: #6c7293;
  font-size: 1.2rem;
}

.stat section {
  flex: 1 1;
}

.stat img,
.stat svg {
  margin-bottom: 24px;
  align-self: flex-end;
}

.stat img {
  width: 44px;
  height: 44px;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .stat h2 {
    padding-bottom: 16px;
    font-size: 1.05rem;
  }

  .stat label {
    padding-bottom: 2px;
    font-size: 1.25rem;
  }

  .stat sub {
    font-size: 1.1rem;
  }

  .stat img,
  .stat svg {
    margin-bottom: 24px;
  }

  .stat svg {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }

  .stat img {
    width: 30px !important;
    height: 30px !important;
  }
}

.tokenomics {
  flex: 0 0 300px;
}

.tokenomics h2 {
  margin-bottom: 40px;
  font-weight: 600;
}

.tokenomics dl {
  margin-top: 80px;
  line-height: 24px;
  overflow: auto;
}

.tokenomics dt {
  width: 100px;
  clear: both;
  float: left;
  font-weight: 600;
}

.tokenomics dd {
  float: left;
}

.tokenomics dd a {
  color: #6c7293;
  font-family: Roboto, Monaco, monospace;
  font-size: 0.95rem;
}

.tokenomics dd a:hover {
  background: #000;
}

.tokenomics dd div {
  margin-bottom: -3px;
}

.tokenomics dd div strong {
  font-weight: 500;
}

.tokenomics dd div:hover strong.toggle,
.tokenomics dd div strong.exact {
  display: none;
}

.tokenomics dd div:hover strong.exact {
  display: inline;
}

.tokenomics dd label {
  margin-left: 6px;
  color: #6c7293;
}

.hodl-forward .wallet {
  padding-left: 100px;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  background-position: 10px 3px;
  background-repeat: no-repeat;
  background-size: 65px 60px;
}

.hodl-forward .wallet svg {
  margin-top: -20px;
  top: 50%;
  left: 30px;
  position: absolute;
}

.hodl-forward .wallet strong {
  font-size: 1.075rem;
  font-weight: 700;
}

.hodl-forward .wallet a {
  margin-left: 7px;
  padding: 0 3px;
  color: #6c7293;
  display: inline-block;
  font-family: Roboto, Monaco, monospace;
  font-size: 1rem;
}

.hodl-forward .wallet a:hover {
  background: #000;
}

.hodl-forward .wallet .balances {
  margin-top: 8px;
}

.hodl-forward .wallet .balances div {
  margin-top: -4px;
}

.hodl-forward .wallet .balances div label {
  padding: 5px 8px 0 0;
  display: inline-block;
  font-size: 1.3rem;
}

.hodl-forward .wallet .balances div label.exact {
  display: none;
}

.hodl-forward .wallet .balances div:hover label.exact {
  display: block;
}

.hodl-forward .wallet .balances div:hover label.toggle {
  display: none;
}

.hodl-forward .wallet .balances div small {
  margin-left: 8px;
  color: #6c7293;
  font-size: 1.1rem;
}

.quote {
  padding: 210px 30px 30px;
  display: block;
  flex: 0 0 200px;
  line-height: 22px;
  text-align: center;
  position: relative;
  background-position: center 55px;
  background-repeat: no-repeat;
  background-size: 110px;
}

.quote label {
  top: 140px;
  right: 50px;
  width: 45px;
  height: 45px;
  color: #000;
  display: block;
  font-family: "American Typewriter", "Courier New";
  font-size: 80px;
  line-height: 85px;
  position: absolute;
  background: #eabc4e;
  border-radius: 100px;
}

.quote p {
  font-style: italic;
}

.quote hr {
  width: 75px;
  margin: 30px auto;
  border: 1px solid #2c2e33;
}

.quote strong,
span {
  display: block;
}

.quote strong {
  font-size: 1.075rem;
  font-weight: 700;
}

.quote span {
  margin-top: 2px;
  margin-bottom: 8px;
  color: #989cb3;
  font-size: 0.975rem;
}

.hodl-links .list {
  margin-top: -18px;
}

.hodl-links .group h2 {
  font-size: 1.375rem;
  font-weight: 500;
  flex: 0 0 30%;
  opacity: 0.8;
}

.hodl-links .group .list {
  margin-top: 0;
  margin-bottom: -4px;
}

.hodl-links .group .list a {
  flex: 0 0 48.5%;
  margin: 5px 0 5px 12px;
  padding: 7px;
  color: #fff;
  display: block;
  text-align: center;
  background: rgba(0, 144, 231, 0.7);
  border: 1px solid rgba(0, 144, 231, 0.2);
  border-radius: 7px;
  box-sizing: border-box;
}

.hodl-links .group .list a:hover {
  background: #00609a;
}

.navbar {
  width: calc(100% - 39px);
  padding: 15px 25px;
  height: 60px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: #191c24;
  z-index: 99999999;
}

.navbar .search {
  flex: 1 1;
  text-align: center;
} */

/* .navbar input {
  width: 450px;
  padding: 10px 14px;
  color: #6c7293;
  font-size: 1.05rem;
  outline: none;
  background: none;
  border: 1px solid #2c2e33;
  border-radius: 6px;
}

.navbar input::-webkit-input-placeholder {
  color: #6c7293;
}

.navbar input:-ms-input-placeholder {
  color: #6c7293;
}

.navbar input::placeholder {
  color: #6c7293;
}

.navbar button {
    position: absolute;
    opacity: 0
}

.navbar div.buy {
  padding-right: 4px;
  text-align: left;
}

.navbar div.buy a {
  padding: 6px 40px;
  text-decoration: none;
  background: #0078c1;
  border: 1px solid #0070b4;
  border-radius: 4px;
}

.navbar div.buy a:hover {
  background: #00609a;
}

@media only screen and (max-width: 768px) {
  .navbar {
    width: calc(100% - 20px);
    padding: 10px;
  }

  .navbar h1 {
    display: none;
  }

  .navbar div.search {
    text-align: left;
  }

  .navbar input {
    width: calc(100% - 22px);
    padding: 7px 10px;
  }
}

.recordings .list {
  margin-top: -8px;
}

.recordings .recording {
  position: relative;
  background: #308098 url(/static/media/ama.0196b833.png) 7px 7px no-repeat;
  background-size: 140px 140px;
  border-radius: 10px;
}

.recordings .episode {
  height: 152px;
  margin-top: 8px !important;
  margin-bottom: 0 !important;
}

.reinvestments .links {
  margin-top: 0 !important;
  width: 155px;
  position: absolute;
}

.reinvestments .links > a {
  margin-bottom: 14px;
  padding: 7px;
  color: #0090e7;
  display: block;
  text-align: center;
  border: 1px solid rgba(0, 144, 231, 0.2);
  border-radius: 7px;
}

.reinvestments .links > a:hover {
  border-color: #0090e7;
}

.reinvestments .links > a.active {
  cursor: default;
  pointer-events: none;
  border-color: rgba(0, 144, 231, 0);
  background: rgba(0, 144, 231, 0.2);
}

.reinvestments .overview {
  margin-left: 189px !important;
}

.table {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
}

.table th,
td {
  padding: 12px;
  color: #6c7293;
}

.table th {
  font-weight: 700;
  text-align: left;
}

.table td {
  border-top: 1px solid #2c2e33;
}

.table tbody {
  border-bottom: 1px solid #2c2e33;
}

.table tbody tr:hover {
  background: #000;
}

.table tbody tr:hover td {
  color: #fff;
  background: #000;
}

.rewards .links {
  margin-top: 0 !important;
  width: 155px;
  position: absolute;
} */

/* .rewards .links > a {
  margin-bottom: 14px;
  padding: 7px;
  color: #ffab00;
  display: block;
  text-align: center;
  border: 1px solid rgba(255, 171, 0, 0.2);
  border-radius: 7px;
}

.rewards .links > a:hover {
  border-color: #ffab00;
}

.rewards .links > a.active {
  cursor: default;
  pointer-events: none;
  border-color: rgba(0, 144, 231, 0);
  background: rgba(255, 171, 0, 0.2);
}

.rewards .overview {
  margin-left: 189px !important;
}

.sidebar {
  width: 16%;
  max-width: 235px;
  min-width: 200px;
  padding-right: 20px;
  padding-top: 45px;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar li a {
  padding: 11px 16px 10px;
  color: #fff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  border-left: 3px solid #191c24;
  border-radius: 0 100px 100px 0;
}

.sidebar li a:hover {
  border-left: 3px solid #ddd !important;
  background: #212530;
}

.sidebar li a.active {
  border-left: 3px solid #0090e7 !important;
  background: #0f1015;
}

.icon,
.sidebar li a i {
  background: rgba(108, 114, 147, 0.2);
  border-radius: 100%;
}

.sidebar li a i {
  width: 29px;
  height: 29px;
  position: relative;
}

.sidebar li a i svg {
  top: 50%;
  left: 50%;
  margin-top: -25%;
  margin-left: -25%;
  position: absolute;
}

.icon {
  width: 15px;
  height: 15px;
  padding: 7px;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: 50%;
}

.sidebar li a span {
  padding-left: 15px;
}

@media only screen and (max-width: 768px) {
  .sidebar {
    width: 32px;
    min-width: auto;
  }

  .sidebar h2,
  .sidebar span {
    display: none;
  }

  .sidebar li {
    margin-bottom: 0;
  }

  .sidebar li a {
    width: 115px;
    padding-left: 10px;
  }
}

.player {
  left: 4px;
  bottom: 0;
  height: 80px;
  position: fixed;
}

.wallets .flex.list {
  align-items: flex-start;
}

.wallets .banner-vertical {
  min-height: 600px;
  flex: 0 0 155px;
  opacity: 0.65;
  background-size: contain;
  background-repeat: no-repeat;
}

.wallets ul {
  flex: 0 0 35%;
}

.wallets ul a.active {
  cursor: default;
}

.wallets ul a.active .box {
  background: #292e3b;
}

.wallets ul a:not(.active) li.box:hover {
  background: #212530;
}

.wallets ul li {
  margin-top: 0;
  margin-bottom: 16px;
}

.wallets ul li .border {
  flex: 0 0 5px;
  background: #fff;
}

.wallets ul li .border.hodl {
  background: #00e6e6;
}

.wallets ul li .border.pancakeswap {
  background: #c88b54;
}

.wallets ul li strong {
  font-size: 1.05rem;
  font-weight: 700;
  opacity: 0.95;
}

.wallets ul li label {
  margin-right: 0;
  padding: 1px 4px;
  cursor: pointer;
  color: #6c7293;
  flex: 0 0 100px;
  text-align: right;
  font-family: Roboto, Monaco, monospace;
  font-size: 0.9rem;
}

.wallets ul li label:hover {
  background: #111;
}

.wallets .selected {
  margin-right: 0 !important;
  padding: 22px 16px;
}

.wallets .selected h2 {
  padding: 40px 0;
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  position: relative;
  background: 50px no-repeat;
}

.wallets .selected .logo {
  top: 5px;
  left: 0;
  width: 160px;
  height: 100%;
  position: absolute;
}

.wallets .selected .box {
  margin-top: 24px;
  font-size: 1.05rem;
  background: #141414;
}

.wallets .selected dl {
  line-height: 30px;
  overflow: auto;
}

.wallets .selected dt {
  width: 100px;
  clear: both;
  float: left;
  font-weight: 600;
}

.wallets .selected dd {
  float: left;
}

.wallets .selected dd a {
  color: #6c7293;
  font-family: Roboto, Monaco, monospace;
  font-size: 0.95rem;
}

.wallets .selected dd a:hover {
  background: #000;
}

.wallets .selected dd div {
  margin-bottom: -3px;
}

.wallets .selected dd div strong {
  font-weight: 500;
}

.wallets .selected dd div:hover strong.toggle,
.wallets .selected dd div strong.exact {
  display: none;
}

.wallets .selected dd div:hover strong.exact {
  display: inline;
}

.wallets .selected dd label {
  margin-left: 6px;
  color: #6c7293;
}

.wallets .selected p {
  margin-top: 20px;
  padding: 10px 20px 9px;
  line-height: 24px;
}

.svg1 {
  width: 1.05rem;
  height: 1.05rem;
  transform: scaleX(-1) scaleY(-1) rotate(180deg);
  transform-origin: center center;
}

.svg2 {
  width: 3.525rem;
  height: 3.525rem;
}

@media only screen and (max-width: 600px) {
  .menu-social {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .responsive-box {
    width: 89%;
  }
  .tokenomics-box {
    width: 87%;
  }
  .last-box {
    width: fit-content;
  }
  .last-box-content {
    margin-left: -90px;
  }
  .chart-link-xs {
    display: block;
  }
  .chart-link-sm {
    display: none;
  }
  .piechart {
    margin-top: -65px;
    padding-bottom: 15px;
  }
  .piechartChild {
    margin-top: -90px;
  }
  .swap-iframe {
    width: 95%;
    height: 95%;
    border-color: #333;
  }
}
.svg1 {
  width: 1.05rem;
  height: 1.05rem;
  transform: scaleX(-1) scaleY(-1) rotate(180deg);
  transform-origin: center center;
}

.svg2 {
  width: 3.525rem;
  height: 3.525rem;
}

@media only screen and (max-width: 600px) {
  .menu-social {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .responsive-box {
    width: 89%;
  }
  .tokenomics-box {
    width: 87%;
  }
  .last-box {
    width: fit-content;
  }
  .last-box-content {
    margin-left: -90px;
  }
  .chart-link-xs {
    display: block;
  }
  .chart-link-sm {
    display: none;
  }
  .piechart {
    margin-top: -65px;
    padding-bottom: 15px;
  }
  .piechartChild {
    margin-top: -90px;
  }
  .swap-iframe {
    width: 95%;
    height: 95%;
    border-color: #333;
  }
}
@media only screen and (min-width: 601px) {
  .swap-iframe {
    width: 95%;
    height: 95%;
    border-color: #333;
  }
  .menu-social {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .chart-link-xs {
    display: none;
  }
  .chart-link-sm {
    display: block;
  }
  .last-box-content {
    margin-left: -90px;
  }
  .piechart {
    margin-top: -20px;
  }
  .piechartChild {
    margin-top: -20px;
  }
}
.buyEver {
  padding: 6px 40px;
  color: #ffffff;
  background-color: #0078c1;
  display: flex;
  flex-direction: row;
}
input {
  border: 2px solid rgb(37, 35, 35);
  border-radius: 5px;
  color: #333;
  background-color: #a5aeb4;
  font-size: 15px;
  margin: 0 0 20px;
  padding: 0.5rem 1rem;
  width: 80%;
}

.tooltip:hover::after,
.tooltip:active::after {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 5px);
  padding: 6px 10px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  opacity: 1;
  visibility: visible;
  z-index: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* .tooltip:hover::before {
    content: attr(title);
    background-color: black;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: pre-wrap;
    z-index: 1;
  } */
