.header-container {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.connect-wallet-button {
  background: linear-gradient(109.59deg, #11b1f4 -32.74%, #085a7d 127.35%);
  padding: 10px 10px;
  color: white;
  font-family: Space Grotesk;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: right;
  border-radius: 4px;
  border: 1px solid white;
}
.connect-wallet-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.img {
  width: 182px;
  height: 66px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.social-icon-Box {
  padding: 0.4rem;
}
.social-icon {
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .img {
    width: 102px;
    height: 36px;
  }
  .connect-wallet-button {
    padding: 6px 6px;
    font-size: 8px;
    text-wrap: nowrap;
  }

  .social-icon-Box {
    padding: 0.2rem;
  }
  .social-icon {
    font-size: 8px;
  }
}
