.total-eth-card {
  width: 100%;
  height: 251px;
  border-radius: 20px;
  background-image: url("../../asset/Images/teth.png");
  background-size: cover; /* Cover the entire background */
  background-position: center;
}

@media screen and (max-width: 768px) {
  .total-eth-card {
    width: 100%;
  }
}
